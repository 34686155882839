// 10 Aug, 10:00am 
export const DateTimes = ({props}) =>{
    const monthObj = {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec"
    }
    let result

    let start_date = props.split('T')               // "2023-10-18T00:00:00.000+00:00"
    start_date = start_date[0]
    start_date = start_date.split('-')
    let month = monthObj[Number(start_date[1])]
    let day = start_date[2]
    result = day +" "+ month +", 00:00am"

    return result
}


// 10 Aug, 10:00am 
export const DateTimesCheck = ({props}) =>{
    let formattedDate

    let start_date = props.split('T')               // "2023-10-18T00:00:00.000+00:00"
    start_date = start_date[0]
    start_date = start_date.split('-')

    let incoming_year = Number(start_date[0])
    let incoming_month = Number(start_date[1])
    let incoming_day = Number(start_date[2])

    const currentDate = new Date(); // Get the current date and time

    // Format the date as per your requirement
    const current_year = Number(currentDate.getFullYear());
    const current_month = Number(currentDate.getMonth() + 1);
    const current_day = Number(currentDate.getDate());

    if(current_year >= incoming_year && current_month >= incoming_month && current_day >= incoming_day){
        formattedDate = true
    } else{
        formattedDate = false
    }

    return formattedDate
}


export const SplitDate = (props) =>{
    let start_date = props?.dateData?.split('T')               // "2023-10-18T00:00:00.000+00:00"
    start_date = start_date[0]
    // //console.log(start_date)

    return start_date
}
