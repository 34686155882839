import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { productData } from "../../static/data";
import styles from "../../styles/styles";
import Collectioncard from "../Cards/CollectionCard/CollectionCard1";
import "../../components/DeliveryinformationCard/DeliveryinformationCard.css";
import Cardskeleton from "../Cards/CollectionCard/Cardskeleton/Cardskeleton";
import CollectionSkeleton from "../Cards/CollectionCard/Cardskeleton/Collectionskeleton";

const Filteredproduct = ({ title, colfilter }) => {
  const { isLoading, allProducts } = useSelector((state) => state.products);

  const [data, setData] = useState();
  const [number, setNumber] = useState(20);

  const LoadmoreItem = () => {
    if (number < allProducts?.length) {
      setNumber(number + 15);
    }
  };

  useEffect(() => {
    // //console.log(allProducts)
    const allProductsData = allProducts ? [...allProducts] : [];
    const filteredData = allProductsData?.filter(
      (productObject) => productObject.category === colfilter
    );
    const firstTwenty = filteredData && filteredData.slice(0, number);
    setData(firstTwenty);
  }, [allProducts, colfilter, number]);

  return (
    <div className="w-[full] bg-[#fff] rounded-lg">
      {/* {data ? ( */}
      <div className={`p-3 ${styles.section} w-full`}>
        <div
          className={`w-[full] text-[16px] font-[600] border-b pb-3 mb-5 seller_information`}
        >
          {title}
        </div>

        <div>
          <div className="grid grid-cols-2 gap-[5px] md:grid-cols-3 md:gap-[5px] lg:grid-cols-4 lg:gap-[5px] xl:grid-cols-5 xl:gap-[5px] mb-12">
            {(isLoading === true ? Array.apply(null, Array(10)) : data)?.map(
              (item, index) => {
                return item ? (
                  <Collectioncard data={item} key={index} />
                ) : (
                  <CollectionSkeleton card={2} key={index} />
                );
              }
            )}
          </div>
          {data?.length < 1 && (
            <div className="w-full text-center">
              No {colfilter} Product Found
            </div>
          )}
        </div>

        <div
          className="w-[100%] flex items-center justify-center rounded-3xl"
          onClick={LoadmoreItem}
        >
          {number <= allProducts?.length && (
            <button className="w-[150px] min-w-120 rounded-3xl p-3 bg-[red] text-[white] font-[500] text-[15px] shadow-md hover:shadow-xl">
              See More
            </button>
          )}
        </div>
      </div>
      {/* ) : null} */}
    </div>
  );
};

export default Filteredproduct;
