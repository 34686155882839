import React, { useState, useEffect } from "react";
import Cardskeleton from "../../components/Cards/CollectionCard/Cardskeleton/Cardskeleton";
import Collectioncard from "../../components/Cards/CollectionCard/CollectionCard1";
import Carousel from "react-multi-carousel";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { Responsive2 } from "../Body/responsive/responsive2";
import { useSelector } from "react-redux";

const Latestproduct = ({ group_title }) => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  let type = false;
  const { isLoading } = useSelector((state) => state.products);

  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const filteredData = allProductsData?.filter(
      (productObject) => productObject.stock < 10
    );
    const firstTwelve = filteredData;
    setData(firstTwelve);
  }, [allProducts]);

  return (
    <div className="thirdBlock-container">
      <div className="w-full my-[5px]">
        <span className="text-[14px] font-[600]">{group_title}</span>
      </div>
      <div className="sub-thirdBlock-container">
        {isLoading === true ? (
          <Cardskeleton cards={10} counts={3} />
        ) : (
          <>
            {data && data?.length !== 0 && (
              <>
                <Carousel
                  className="p-1 static"
                  minimumTouchDrag={20}
                  responsive={Responsive2}
                  customLeftArrow={
                    <FaChevronCircleLeft id="carousel-icons-left" />
                  }
                  customRightArrow={
                    <FaChevronCircleRight id="carousel-icons-right" />
                  }
                >
                  {data &&
                    data?.map((i, index) => (
                      <Collectioncard type={type} data={i} key={index} />
                    ))}
                </Carousel>
              </>
            )}{" "}
          </>
        )}
      </div>

      {/* {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null} */}
    </div>
  );
};

export default Latestproduct;
