import React, { useState, useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";
import { useSelector } from "react-redux";
import Cardskeleton from "../Cards/CollectionCard/Cardskeleton/Cardskeleton";
import Carousel from "react-multi-carousel";
import { Responsive2 } from "../../pages/Body/responsive/responsive2";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import Collectioncard3 from "../Cards/CollectionCard/CollectionCard3";
import SmallestTime from "./SmallestTime";

const Flashsalescard = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);
  const [data, setData] = useState(null);
  const [productdata, setProductdata] = useState(null);

  useEffect(() => {
    setProductdata(allEvents);
    const allEventsData = allEvents ? [...allEvents] : [];
    const firstTwelve = allEventsData && allEventsData.slice(0, 12);
    setData(firstTwelve);
  }, [allEvents]);

  return (
    <div className="fourth-Block-container">
      <div className="display-flex">
        <span>Flash Sales</span>

        <div className="flex gap-2 items-center">
          <span className="text-[white] text-[14px] font-[600]">
            Time Left:{" "}
          </span>
          <strong>
            <SmallestTime data={productdata} />
          </strong>
        </div>

        <span>
          {/* <span>See All</span> */}
          <BiChevronRight id="chevron-icon" />
        </span>
      </div>

      <div className="sub-thirdBlock-container py-[10px]">
        <div className="sub-thirdBlock-container">
          {isLoading === true ? (
            <Cardskeleton cards={10} counts={3} />
          ) : (
            <>
              {data && data?.length !== 0 && (
                <>
                  <Carousel
                    className="p-1 static"
                    minimumTouchDrag={20}
                    responsive={Responsive2}
                    customLeftArrow={
                      <FaChevronCircleLeft id="carousel-icons-left" />
                    }
                    customRightArrow={
                      <FaChevronCircleRight id="carousel-icons-right" />
                    }
                  >
                    {data &&
                      data?.map((i, index) => (
                        <Collectioncard3 data={i} key={index} />
                      ))}
                  </Carousel>
                </>
              )}{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Flashsalescard;
