import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

// get All Products of a shop
export const getAllCategories = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllCategories",
    });

    const { data } = await axios.get(`${server}/category`);
    dispatch({
      type: "getAllCategoriesSuccess",
      payload: data.categories,
    });
  } catch (error) {
    dispatch({
      type: "getAllCategoriesFailed",
      payload: error.response.data.message,
    });
  }
};

// Add New Category
export const createCategories = (categoryfield) => async (dispatch) => {
  try {
    dispatch({
      type: "createCategories",
    });

    var config = {
      method: "POST",
      url: `${server}/category/create`,
      data: {
        ...categoryfield,
      },
      headers: {
        // Authorization: "Bearer " + cookies.Token
      },
    };

    await axios(config).then((response) => {
      if (response.data.success === true) {
        dispatch({
          type: "createCategoriesSuccess",
          payload: response.data.data,
        });

        toast.success("Catalog Added Successfully");
      }
    });
  } catch (error) {
    dispatch({
      type: "createCategoriesFailed",
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

// Add Delete Category by ID
export const deleteCategory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteCategory",
    });

    const { data } = await axios.delete(`${server}/category/delete/${id}`);
    toast.success(data.message);
    dispatch({
      type: "deleteCategoriesSuccess",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "deleteCategoriesFailed",
      payload: error.response.data.message,
    });
  }
};

// Save all categories
export const allCategories = (categoriesdata) => ({
  type: "categoriesData",
  payload: categoriesdata,
});
