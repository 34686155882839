import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Layout/Header/Header";
import Latestproduct from "./LatestProduct/Latestproduct";
import Footer from "../components/Layout/Footer";
import AllProduct from "../components/Products/Product";
import Limitedcollection from "./LatestProduct/Limitedcollection";
import { useSelector } from "react-redux";
import Filteredproduct from "../components/Products/Productfiltered";

const Collections = () => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  const { isLoading } = useSelector((state) => state.products);

  const { collection } = useParams();
  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    //console.log(allProductsData);
    const filteredData = allProductsData?.filter(
      (productObject) =>
        productObject.category === collection && productObject.stock < 15
    );
    const firstTwelve = filteredData;
    setData(firstTwelve);
  }, [allProducts, collection]);

  const image_Url =
    "https://ng.jumia.is/cms/0-0-black-friday/2023/Brand-Partners/Xiaomi/1152x252-XIAOMI.jpg";

  return (
    <div className="w-full h-full flex flex-col min-w-[900px]">
      <Header />

      <div className="w-full min-w-[900px] min-h-[100vh] flex flex-col gap-5 bg-[#f5f5f5] px-10">
        <div className="w-full mt-5 flex items-center text-[12px]">
          Home &gt; {collection}
        </div>

        <div className="shadow text-center rounded-md p-2 bg-[#fff] font-[600] text-[18px]">
          {collection}
        </div>

        <div className="w-full rounded-md p-2 bg-[#fff] h-[300px] hover:shadow">
          <div
            style={{ backgroundImage: `url(${image_Url})` }}
            className="w-full h-full bg-cover hover:shadow-lg"
          />
        </div>

        {data && data?.length !== 0 ? (
          <Limitedcollection
            group_title={`Limited ${collection} Deals`}
            colfilter={collection}
          />
        ) : (
          <></>
        )}

        <div className="flex w-full items-center justify-center gap-5 m-y-2 mt-4">
          <div className="w-[10%] border-2 border-solid" />
          <h1 className="text-[18px] text-bold font-[800] text-[#333]">
            All {collection}
          </h1>
          <div className="w-[10%] border-2 border-solid" />
        </div>
        <Filteredproduct colfilter={collection} title="" />
      </div>

      <Footer />
    </div>
  );
};

export default Collections;
