import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";

const OrderDetails = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const data = orders && orders.find((item) => item._id === id);

  const orderUpdateHandler = async (e) => {
    await axios
      .put(
        `${server}/order/update-order-status/${id}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Order updated!");
        navigate("/dashboard-orders");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const refundOrderUpdateHandler = async (e) => {
    await axios
      .put(
        `${server}/order/order-refund-success/${id}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Order updated!");
        dispatch(getAllOrdersOfShop(seller._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className={`py-4 gap-1 flex flex-col min-h-screen w-11/12 mx-auto`}>
      <div className="w-full flex items-center justify-between">
        <div className="w-full  px-2 border">
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center">
              <BsFillBagFill size={30} color="crimson" />
              <h1 className="pl-2 text-[25px] font-[500]">Order Details</h1>
            </div>

            <Link to="/dashboard-orders">
              <div
                className={`${styles.button} !bg-[#fce1e6] !rounded-[4px] text-[#e94560] font-[600] !h-[45px] text-[18px]`}
              >
                Order List
              </div>
            </Link>
          </div>

          <div className="w-full flex items-center justify-between">
            <h5 className="text-[#00000084] text-[13px]">
              Order ID: <span>#{data?._id?.slice(0, 8)}</span>
            </h5>
            <h5 className="text-[#00000084] text-[13px]">
              Placed on: <span>{data?.createdAt?.slice(0, 10)}</span>
            </h5>
          </div>
        </div>
      </div>

      <br />

      {/* order items */}
      {data &&
        data?.cart?.map((item, index) => (
          <div className="w-full border px-2 flex items-center py-3">
            <img
              src={`${item.images[0]?.url}`}
              alt=""
              className="w-[50x] h-[50px]"
            />
            <div className="w-full">
              <h5 className="pl-3 text-[15px]">{item.name}</h5>
              <h5 className="pl-3 text-[13px] text-[#00000091]">
                <NumberFormat
                  value={item?.discountPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />{" "}
                X {item?.qty}
              </h5>
            </div>
          </div>
        ))}

      <div className="border-t w-full text-right border bg-[#4444] px-2">
        <h5 className="pt-3 text-[18px]">
          Total Price:{" "}
          <strong>
            {" "}
            <NumberFormat
              value={data?.totalPrice}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₦"}
            />{" "}
          </strong>
        </h5>
      </div>

      <br />
      <div className="w-full 800px:flex items-center gap-2">
        <div className="w-full h-[150px] 800px:w-[60%] border px-2">
          <h4 className="pt-3 text-[16px] font-[600]">Shipping Address:</h4>
          <h4 className="pt-3 text-[14px]">
            {data?.shippingAddress.address1 +
              " " +
              data?.shippingAddress.address2}
          </h4>
          <h4 className=" text-[14px]">{data?.shippingAddress.country}</h4>
          <h4 className=" text-[14px]">{data?.shippingAddress.city}</h4>
          <h4 className=" text-[14px]">{data?.user?.phoneNumber}</h4>
        </div>

        <div className="w-full h-[150px] 800px:w-[40%] border px-2">
          <h4 className="pt-3 text-[16px] font-[600]">Payment Info:</h4>
          <h4 className="pt-3 text-[14px]">
            Status:{" "}
            {data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not Paid"}
          </h4>
          <br />
        </div>
      </div>

      <br />

      <div className="flex flex-col gap-2 w-full">
        <h4 className="text-[16px] font-[600]">Order Status:</h4>

        {data?.status !== "Processing refund" &&
          data?.status !== "Refund Success" && (
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-[200px] mt-2 border h-[35px] rounded-[5px]"
            >
              {[
                "Processing",
                "Transferred to delivery partner",
                "Shipping",
                "Received",
                "On the way",
                "Delivered",
              ]
                .slice(
                  [
                    "Processing",
                    "Transferred to delivery partner",
                    "Shipping",
                    "Received",
                    "On the way",
                    "Delivered",
                  ].indexOf(data?.status)
                )
                ?.map((option, index) => (
                  <option value={option} key={index}>
                    {option}
                  </option>
                ))}
            </select>
          )}

        {data?.status === "Processing refund" ||
        data?.status === "Refund Success" ? (
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-[200px] border p-2 text-[13px] rounded-[5px]"
          >
            {["Processing refund", "Refund Success"]
              .slice(
                ["Processing refund", "Refund Success"].indexOf(data?.status)
              )
              ?.map((option, index) => (
                <option className="text-[13px]" value={option} key={index}>
                  {option}
                </option>
              ))}
          </select>
        ) : null}

        <div
          className={`!bg-[#FCE1E6] cursor-pointer w-fit !rounded-[4px] text-[#E94560] font-[600] p-2 text-[16px]`}
          onClick={
            data?.status !== "Processing refund"
              ? orderUpdateHandler
              : refundOrderUpdateHandler
          }
        >
          Update Status
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
