import React from "react";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { MdOutlinePhonelink, MdSportsTennis } from "react-icons/md";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { GiTravelDress, GiPineapple, GiHealthPotion, GiWineBottle, GiBabyFace, GiConverseShoe } from "react-icons/gi";
import { GrGamepad, GrCar, GrMonitor } from "react-icons/gr";
import { GoHome } from "react-icons/go";
import { CgMoreO } from "react-icons/cg";
import { HiComputerDesktop } from "react-icons/hi2";
import { BsWatch } from "react-icons/bs"
import { LuDog } from "react-icons/lu"
import { FcSmartphoneTablet, FcElectronics  } from "react-icons/fc";

import "./sidebar.css";
import { categoriesData } from "../../static/data";
import Skeleton from "react-loading-skeleton";

const Sidebar = ({ loading }) => {
  const buttonClicks = useSelector((state) => state.buttonclick);

  const Iconobj = {
    0 : <HiComputerDesktop id='bar-icons'/>,
    1 : <GiPineapple id='bar-icons'/>,
    2 : <GrMonitor id='bar-icons'/>,
    3 : <BsWatch  id='bar-icons'/>,
    4 : <GiTravelDress id='bar-icons'/>,
    5 : <GiConverseShoe id='bar-icons'/>,
    6 : <FcElectronics id='bar-icons'/>,
    7 : <LuDog id='bar-icons'/>,
    8 : <FcSmartphoneTablet id='bar-icons'/>,
    9 : <GrGamepad id='bar-icons'/>,
    10 : <MdSportsTennis id='bar-icons'/>,
    11 : <GiHealthPotion id='bar-icons'/>,
    12 : <GrCar id='bar-icons'/>,
    13 : <CgMoreO id='bar-icons'/>
  }

  return (
    <div className="NavContain">
      <ul className="productsidebr">
        {categoriesData?.map((prodCateg, index) => (
          <li key={index} className="hover:bg-[#f9f9f8]">
            <Link to={`/${prodCateg.title}`} className="w-full flex">
              {loading ? (
                <Skeleton
                  containerClassName="bar-icons"
                  width="100%"
                  height="100%"
                />
              ) : (
                // <div
                //   style={{ backgroundImage: `url(${prodCateg.image_Url})` }}
                //   id="bar-icons"
                // />
                <span>{Iconobj[index]}</span>
              )}
              <p className="text-[11px]">{prodCateg.title}</p>
              
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
