import React from 'react'
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import WithdrawMoney from "../../components/Shop/WithdrawMoney";
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';

const ShopWithDrawMoneyPage = () => {
  return (
    <div className="w-full h-[100vh] flex flex-col">
      <DashboardHeader />
      <div className="flex bg-[#3333] justify-between relative w-full">
        <div className="w-[80px] sticky 800px:w-[330px]">
          <DashboardSideBar active={7} />
        </div>
        <div className="w-full justify-center bg-[#f9f9f9] m-1 overflow-y-auto flex">
          <WithdrawMoney />
        </div>
      </div>
    </div>
  )
}

export default ShopWithDrawMoneyPage