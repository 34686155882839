import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllOrdersOfUser } from "../../../redux/actions/order";
import animationProcessing from "../../../Assests/animations/order-processing.json";
import animationShipped from "../../../Assests/animations/shipped-order.json";
import animationDelivered from "../../../Assests/animations/delivered-order.json";
import refundProcessing from "../../../Assests/animations/animation-refund.json";
import animationNoOrderYet from "../../../Assests/animations/no-orderyet.json";
import refundSuccessful from "../../../Assests/animations/refund-successful.json";
import Lottie from "lottie-react";

const TrackOrder = () => {
  const style = {
    height: 200,
  };

  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch]);

  const data = orders && orders.find((item) => item._id === id);

  return (
    <div className="w-full h-[80vh] gap-[20px] bg-white flex flex-col justify-center items-center">
      <Lottie
        animationData={
          data && data?.status === "Processing"
            ? animationProcessing
            : data?.status === "Shipping"
            ? animationShipped
            : data?.status === "Received"
            ? animationShipped
            : data?.status === "On the way"
            ? animationShipped
            : data?.status === "Delivered"
            ? animationDelivered
            : data?.status === "Processing refund"
            ? refundProcessing
            : data?.status === "Refund Success"
            ? refundSuccessful
            : animationNoOrderYet
        }
        style={style}
      />{" "}
      <>
        {data && data?.status === "Processing" ? (
          <h1 className="text-[16px] text-[green]">
            Your Order is processing in shop.
          </h1>
        ) : data?.status === "Transferred to delivery partner" ? (
          <h1 className="text-[16px] text-[green]">
            Your Order is on the way for delivery partner.
          </h1>
        ) : data?.status === "Shipping" ? (
          <h1 className="text-[16px] text-[green]">
            Your Order is on the way with our delivery partner.
          </h1>
        ) : data?.status === "Received" ? (
          <h1 className="text-[16px] text-[green]">
            Your Order is in your city. Our Delivery man will deliver it.
          </h1>
        ) : data?.status === "On the way" ? (
          <h1 className="text-[16px] text-[green]">
            Our Delivery man is going to deliver your order.
          </h1>
        ) : data?.status === "Delivered" ? (
          <h1 className="text-[16px] text-[green]">Your order is delivered!</h1>
        ) : data?.status === "Processing refund" ? (
          <h1 className="text-[16px] text-[green]">
            Your refund is processing!
          </h1>
        ) : data?.status === "Refund Success" ? (
          <h1 className="text-[16px] text-[green]">
            Your Refund is successful!
          </h1>
        ) : null}
      </>
    </div>
  );
};

export default TrackOrder;
