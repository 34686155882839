import React from 'react'
import './Storenavbar.css'
import { BiChevronDown } from 'react-icons/bi'

const Shopnavbar = () => {
  return (
    <div className='shopnav-bar-container'>
        <li>Store home</li>
        <li>
          Products
          <BiChevronDown/>
        </li>
        <li>Top Selling</li>
        <li>Feedback</li>
    </div>
  )
}

export default Shopnavbar