import React, { useEffect, useState } from "react";
import { server } from "../../server";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineDelete } from "react-icons/ai";
import { Button } from "@mui/material";
import { IoCloseCircleSharp, IoWarning } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  createCategories,
  deleteCategory,
  getAllCategories,
} from "../../redux/actions/category";
import { useDispatch, useSelector } from "react-redux";

const AllCategories = () => {
  const dispatch = useDispatch();
  const { categorylist } = useSelector((state) => state.category);
  const [data, setData] = useState(categorylist);
  const [views, setViews] = useState(false);
  const [hide, setHide] = useState(false);
  const [confirmdelete, setConfirmdelete] = useState({
    status: false,
    id: "",
  });
  const [categoryfield, setCategoryfield] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    setData(categorylist);
  }, [categorylist]);

  const handleCollectionformSubmit = async (e) => {
    e.preventDefault();
    dispatch(createCategories(categoryfield));
    setCategoryfield({ name: "", description: "" });
    setViews(!views);
  };

  const handleDelete = (id) => {
    setConfirmdelete({
      status: true,
      id,
    });
  };

  const columns = [
    { field: "sn", headerName: "#SN", minWidth: 60, flex: 0 },
    { field: "id", headerName: "Category Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 0.8,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "Delete",
      flex: 0.3,
      minWidth: 80,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = data?.map((item, index) => ({
    sn: index + 1,
    id: item._id, // Assign sequential numbers starting from 1
    name: item.name,
    description: item.description,
  }));

  const handleAddNewCategory = () => {
    setViews(!views);
  };

  const handleClose = () => {
    setHide(!hide);
  };

  const handleCloseModal = () => {
    setViews(!views);
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setCategoryfield({
      ...categoryfield,
      [name]: value,
    });
  };

  const handleConfirm = (props) => {
    if (props.text === "Yes") {
      dispatch(deleteCategory(props.sn));
      setConfirmdelete(false);
    } else {
      setConfirmdelete(false);
    }
  };

  return (
    <div className="w-full h-full flex justify-center pt-5 relative">
      <div className="w-[97%]">
        <h3 className="text-[18px] text-bold font-Poppins pb-2">
          All Categories
        </h3>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>

        <div className="w-full p-2 flex items-center justify-end">
          <button
            className="text-[white] text-[13px] bg-[green] text-bold p-3"
            onClick={handleAddNewCategory}
          >
            NEW CATEGORY
          </button>
        </div>
      </div>

      {views && (
        <div className="w-full bg-[#00000029] h-full flex justify-center items-center absolute top-0">
          <div className="flex flex-col gap-3 items-center bg-[white] min-h-screenw-[280px] w-[80%] p-5 py-7">
            <div className="w-full flex items-center justify-center relative">
              <h5 className="text-[20px] font-[600] text-center">
                Create Categories
              </h5>

              <IoCloseCircleSharp
                size={26}
                color="#002D62"
                onClick={handleCloseModal}
                className="cursor-pointer absolute right-0"
              />
            </div>

            {hide && (
              <div className="flex gap-2 text-[10px] p-2 bg-[orange] items-center w-fit rounded-[3px]">
                <IoWarning size={16} color="yellow" />
                <p className="text-[#fff]">
                  Note: you can only create maximum of 11 collections
                </p>

                <IoCloseCircleSharp
                  size={16}
                  color="yellow"
                  onClick={handleClose}
                  className="cursor-pointer"
                />
              </div>
            )}

            <form
              onSubmit={handleCollectionformSubmit}
              className="flex flex-col gap-3 text-[13px] w-[60%] min-w-[300px]"
            >
              <div className="flex gap-2 w-full">
                <div className="w-full">
                  <label className="pb-2">
                    Category <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={categoryfield.name}
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => handleCategoryChange(e)}
                    placeholder="Enter Category Label"
                  />
                </div>
              </div>

              <div className="flex gap-2 w-full">
                <div className="w-full">
                  <label className="pb-2">
                    Description <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="description"
                    value={categoryfield.description}
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => handleCategoryChange(e)}
                    placeholder="Enter Category Description"
                  />
                </div>
              </div>

              <div className="flex gap-2 w-full">
                <div className="w-full">
                  <input
                    type="submit"
                    name="name"
                    value="SUBMIT"
                    className="mt-2 cursor-pointer hover:bg-[#002D62] bg-[#002e6253] text-white appearance-none block w-full p-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {confirmdelete.status && (
        <div className="w-full bg-[#00000029] h-full flex justify-center items-center absolute top-0">
          <div className="flex h-[25vh] flex-col gap-5 justify-start items-center bg-[white] w-[50%] p-5 py-7 min-w-[250px] max-w-[500px]">
            <span className="text-[15px] text-[700] text-center">
              Are you sure you want to delete?
            </span>
            <div className="flex items-center gap-3">
              <button
                className="text-[white] text-[14px] bg-[green] px-5 py-3 shadow"
                onClick={(e) =>
                  handleConfirm({ text: "Yes", sn: confirmdelete.id })
                }
              >
                Yes
              </button>

              <button
                className="text-[white] text-[14px] bg-[red] px-5 py-3 shadow"
                onClick={(e) => handleConfirm({ text: "No", sn: "0" })}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllCategories;
