import React, { useState } from "react";
import "../../pages/Body/Body.css";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import Cards from "../Cards/Cards";
import Bigcard from "../Cards/Bigcard";

const Productstorefront = () => {
  const [menubar, setMenubar] = useState(false);
  const { isLoading } = useSelector((state) => state.products);

  return (
    <div>
      {isLoading ? (
        <div className="firstBlock-container">
          <Sidebar setsidebr={menubar} isLoading={true} />
          <div className="scroll-screen-container">
            <Skeleton
              containerClassName="bigcard-container"
              width="100%"
              height="100%"
            />
          </div>
          <div className="top-right-container">
            <Skeleton
              containerClassName="card-container"
              width="100%"
              height="100%"
            />
            <Skeleton
              containerClassName="card-container"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      ) : (
        <div className="firstBlock-container relative">
          <Sidebar setsidebr={menubar} isLoading={false} />
          <div className="scroll-screen-container">
            <Bigcard />
          </div>
          <div className="top-right-container gap-2">
            <Cards image_Url="https://res.cloudinary.com/dswna4lpk/image/upload/v1689761883/ptvztr6jwfzahpwozmcq.png" />
            <Cards image_Url="https://res.cloudinary.com/dswna4lpk/image/upload/v1689764253/pcd3evgp5q5dbrirxxky.png" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Productstorefront;
