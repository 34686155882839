import React from 'react'
import './CollectionCard.css'

const BigCard = ({id, image_Url, textdiscount, title, subtext}) => {
  return (
    <div className='big-card-container'>
        <img className='bigimage-container' src={image_Url} alt='kira-store'/>
    </div>
  )
}

export default BigCard