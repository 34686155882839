import React,{useState} from 'react'
import { useSelector } from 'react-redux'
import CollectionSkeleton from '../../components/Cards/CollectionCard/Cardskeleton/Collectionskeleton'
import CollectionCard4 from '../../components/Cards/CollectionCard/CollectionCard4'
import { JsonCollection } from '../../static/data'

const ProductCollections = () => {
  const [collections, setCollections] = useState(JsonCollection)
  const {isLoading } = useSelector((state) => state.products)
  return (
      <div className="fourth-Block-container">
            <div className="w-[100%] min-h-[250px] mt-[15px] pb-[15px] flex flex-wrap bg-[#fff] p-[10px] gap-2">
                {collections?.map((item) => {
                  return(
                    <>
                      {
                        isLoading === true ? 
                        <CollectionSkeleton card={1}/> : 
                        <CollectionCard4 
                          product={item.product} 
                          imgurl={item.url}
                        />
                      }
                    </> 
                  )
                })} 
            </div>
      </div>
  )
}

export default ProductCollections