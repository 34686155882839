import React from 'react'
import './Cards.css'

const Cards = ({image_Url}) => {
  return (
    <div className='card-container'>
        <img className='bigimage-containe' src={image_Url} alt='kira-store'/>
    </div>
  )
}

export default Cards