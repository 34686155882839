import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
// import { loadSeller } from "../../redux/actions/sellers";
import { AiFillCloseCircle, AiOutlineDelete } from "react-icons/ai";
import NumberFormat from "react-number-format";
import { loadSeller } from "../../redux/actions/sellers";

const WithdrawMoney = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { seller } = useSelector((state) => state.seller);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(50);
  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    bankCountry: "",
    bankSwiftCode: null,
    bankAccountNumber: null,
    bankHolderName: "",
    bankAddress: "",
  });

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const withdrawMethod = {
      bankName: bankInfo.bankName,
      bankCountry: bankInfo.bankCountry,
      bankSwiftCode: bankInfo.bankSwiftCode,
      bankAccountNumber: bankInfo.bankAccountNumber,
      bankHolderName: bankInfo.bankHolderName,
      bankAddress: bankInfo.bankAddress,
    };

    setPaymentMethod(false);

    await axios
      .put(
        `${server}/shop/update-payment-methods`,
        {
          withdrawMethod,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Withdraw method added successfully!");
        dispatch(loadSeller());
        setBankInfo({
          bankName: "",
          bankCountry: "",
          bankSwiftCode: null,
          bankAccountNumber: null,
          bankHolderName: "",
          bankAddress: "",
        });
      })
      .catch((error) => {
        //console.log(error.response.data.message);
      });
  };

  const deleteHandler = async () => {
    await axios
      .delete(`${server}/shop/delete-withdraw-method`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Withdraw method deleted successfully!");
        dispatch(loadSeller());
      });
  };

  const error = () => {
    toast.error("You not have enough balance to withdraw!");
  };

  const withdrawHandler = async () => {
    if (withdrawAmount < 50 || withdrawAmount > availableBalance) {
      toast.error("You can't withdraw this amount!");
    } else {
      const amount = withdrawAmount;
      await axios
        .post(
          `${server}/withdraw/create-withdraw-request`,
          { amount },
          { withCredentials: true }
        )
        .then((res) => {
          toast.success("Withdraw money request is successful!");
        });
    }
  };

  const availableBalance = seller?.availableBalance.toFixed(2);

  return (
    <div className="w-full h-[90vh] p-8">
      <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
        <h5 className="text-[20px] pb-4">
          Available Balance:{" "}
          <NumberFormat
            value={availableBalance}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₦"}
          />
        </h5>
        <div
          className={`flex justify-center items-center cursor-pointer text-[white] bg-[blue] p-3 min-w-[120px] !rounded`}
          onClick={() => (availableBalance < 50 ? error() : setOpen(true))}
        >
          Withdraw
        </div>
      </div>
      {open && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          <div
            className={`w-[95%] 800px:w-[60%] bg-white shadow ${
              paymentMethod ? "overflow-y-auto" : "h-[unset]"
            } min-h-[40vh] p-4`}
          >
            <div className="w-full flex mb-5 justify-center items-center relative">
              <AiFillCloseCircle
                id="close-icon"
                size={30}
                onClick={() => setOpen(false) || setPaymentMethod(false)}
              />

              <h3 className="text-[18px] text-center font-[600]">
                Add New Withdraw Method:
              </h3>
            </div>

            {paymentMethod ? (
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="w-full flex-wrap flex items-center mb-2 gap-2">
                    <div className="w-[45%] grow min-w-[250px]">
                      <label className="text-[13px]">
                        Bank Name <span className="text-red-500">*</span>
                      </label>

                      <input
                        type="text"
                        name=""
                        required
                        value={bankInfo.bankName}
                        onChange={(e) =>
                          setBankInfo({ ...bankInfo, bankName: e.target.value })
                        }
                        id=""
                        placeholder="Enter your Bank name!"
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    <div className="w-[45%] grow min-w-[250px]">
                      <label className="text-[13px]">
                        Bank Country <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        value={bankInfo.bankCountry}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankCountry: e.target.value,
                          })
                        }
                        id=""
                        required
                        placeholder="Enter your bank Country!"
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="w-full flex-wrap flex items-center mb-2 gap-2">
                    <div className="w-[45%] grow min-w-[250px]">
                      <label className="text-[13px]">
                        Bank Swift Code <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        value={bankInfo.bankSwiftCode}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankSwiftCode: e.target.value,
                          })
                        }
                        placeholder="Enter your Bank Swift Code!"
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    <div className="w-[45%] grow min-w-[250px]">
                      <label className="text-[13px]">
                        Bank Account Number{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name=""
                        id=""
                        value={bankInfo.bankAccountNumber}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAccountNumber: e.target.value,
                          })
                        }
                        required
                        placeholder="Enter your bank account number!"
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="w-full flex-wrap flex items-center mb-2 gap-2">
                    <div className="w-[45%] grow min-w-[250px]">
                      <label className="text-[13px]">
                        Bank Holder Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        value={bankInfo.bankHolderName}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankHolderName: e.target.value,
                          })
                        }
                        id=""
                        placeholder="Enter your bank Holder name!"
                        className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    <div className="w-[45%] grow min-w-[250px]">
                      <label className="text-[13px]">
                        Bank Address <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        id=""
                        value={bankInfo.bankAddress}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAddress: e.target.value,
                          })
                        }
                        placeholder="Enter your bank address!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className={`w-full bg-[#3a24db] h-[40px] border border-[#3a24db] text-center text-[#ffff] rounded-[3px] mt-8 cursor-pointer`}
                  >
                    Add
                  </button>
                </form>
              </div>
            ) : (
              <>
                <h3 className="text-[15px] font-[500] mb-2">
                  Available Withdraw Methods:
                </h3>

                {seller && seller?.withdrawMethod ? (
                  <div className="w-full flex flex-col gap-3 justify-center">
                    <div className="flex w-full p-4 border justify-between items-center">
                      <div className="w-full">
                        <h5>
                          Account Number:{" "}
                          {"*".repeat(
                            seller?.withdrawMethod.bankAccountNumber?.length - 3
                          ) +
                            seller?.withdrawMethod.bankAccountNumber.slice(-3)}
                        </h5>
                        <h5>Bank Name: {seller?.withdrawMethod.bankName}</h5>
                      </div>
                      <div>
                        <AiOutlineDelete
                          size={25}
                          className="cursor-pointer"
                          onClick={() => deleteHandler()}
                        />
                      </div>
                    </div>

                    <div className="w-full border bg-[#00000010] p-4 py-2">
                      <h4 className="flex items-center">
                        Available Balance:{" "}
                        <p className="text-[25px] text-[green] ml-2 font-[700]">
                          <NumberFormat
                            value={availableBalance}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦"}
                          />
                        </p>
                      </h4>
                    </div>

                    <div className="800px:flex w-full items-center">
                      <input
                        type="number"
                        placeholder="Amount..."
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        className="800px:w-[100px] w-[full] border 800px:mr-3 p-1 rounded"
                      />
                      <div
                        className={`flex justify-center items-center p-4 text-[14px] bg-[#3a24db] h-[35px] border border-[#3a24db] text-center text-[#ffff] rounded-[3px] cursor-pointer`}
                        onClick={withdrawHandler}
                      >
                        Withdraw
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="border p-2">
                    <p className="text-[13px] font-[300">
                      No Withdraw Methods available!
                    </p>
                    <div className="w-full flex items-center">
                      <div
                        className={`flex justify-center items-center p-4 text-[14px] bg-[#3a24db] h-[40px] border border-[#3a24db] text-center text-[#ffff] rounded-[3px] mt-8 cursor-pointer`}
                        onClick={() => setPaymentMethod(true)}
                      >
                        Add new
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawMoney;
