import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  categorylist: [],
};

export const categoryReducer = createReducer(initialState, {
  // get all orders of user
  getAllCategories: (state) => {
    state.isLoading = true;
  },

  getAllCategoriesSuccess: (state, action) => {
    state.isLoading = false;
    state.categorylist = action.payload;
  },
  getAllCategoriesFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all category of shop
  createCategories: (state) => {
    state.isLoading = true;
  },
  createCategoriesSuccess: (state, action) => {
    state.isLoading = false;
    state.categorylist = action.payload;
  },
  createCategoriesFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  deleteCategory: (state) => {
    state.isLoading = true;
  },
  deleteCategoriesSuccess: (state, action) => {
    state.isLoading = false;
    state.categorylist = action.payload;
  },
  deleteCategoriesFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  categoriesData: (state, action) => {
    state.isLoading = false;
    state.categorylist = action.payload;
  },
});
