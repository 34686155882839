import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./redux/store";
import { AdminProvider } from "./components/context/AdminContext";
import { SidebarProvider } from "./components/context/SidebarContext";
import { Windmill } from "@windmill/react-ui";

const root = createRoot(document.getElementById("root"));
root.render(
  <AdminProvider>
    <SidebarProvider>
      <Provider store={Store}>
        <App />
      </Provider>
    </SidebarProvider>
  </AdminProvider>
);

reportWebVitals();
