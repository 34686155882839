import React, { useState, useEffect } from "react";
// import LOGO from '../../../assets/logo.png'
import "./Navbar.css";
import {
  AiOutlineSearch,
  AiOutlineShopping,
} from "react-icons/ai";
// import { GiHamburgerMenu } from "react-icons/gi";
import { BiSearchAlt2 } from "react-icons/bi";
// import Sidebar from "../Sidebar/Sidebar";
import { NavLink, useNavigate } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";
import { FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Wishlist from "../../../Wishlist/Wishlist";
// import Cart from "../../cart/Cart";
// import Wishlist from "../../Wishlist/Wishlist";
// import { IoIosArrowForward } from "react-icons/io";
import Logo from '../../../../Assests/shop_logo/kslogo1.png'


const Navbar = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products)
  // const currentTab = useSelector((state) => state.currentTab.currentPage);
  const [active, setActive] = useState(false);
  // const [dropDown, setDropDown] = useState(false);
  // const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  // const [open, setOpen] = useState(false);


  const [search, setSearch] = useState(true);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [searchData, setSearchData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  const searchbar = () => {
    setSearch(!search);
  };

  const searchbar2 = () => {
    if (!search) {
      setSearch(!search);
    }
  };

  return (
    <>
      <div className="navbar-container" onClick={searchbar2}>
        <div className="menuparentcontain">
          <Link to='/'>
            {/* <div className="LogoIcon" alt="logoIcon" onClick={handleClick} /> */}
            <img
              // src="https://res.cloudinary.com/dswna4lpk/image/upload/v1697226169/Bag_alt_pgykgr.png"
              src={Logo}
              alt=""
              className="h-[60px] w-[65px] mb-3"
            />
          </Link>
        </div>

        {/* {currentTab !== 1 ? */}
          <>
            <div className="searchContainer">
              <div className={search ? "searchbarContain" : "searchbarContainvis"}>
                <AiOutlineSearch id="searchicon" />
                <input
                  className="searchfield"
                  type="text"
                  placeholder="Search Products, Brands and Categories"
                  onChange={handleSearchChange}
                  value={searchTerm}
                />
                <AiOutlineSearch className="SearchCloseicon" onClick={searchbar2} />


                {/* <button>Search</button> */}
                {searchData && searchData?.length !== 0 ? (
                  <div className={searchTerm !== "" ? "min-h-[20vh] max-h-[30vh] bg-slate-50 shadow-sm-2 z-[9] p-2 search_filetered_container overflow-y-auto" : "hidden"}>
                    {searchData &&
                      searchData?.map((i, index) => {
                        return (
                          <Link to={`/product/${i._id}`}>
                            <div className="w-full flex items-start-py-3 mb-1 items-center">
                              <img
                                src={`${i.images[0]?.url}`}
                                alt=""
                                className="w-[30px] h-[30px] mr-[10px]"
                              />
                              <div className="w-[calc(97%-30px)]">
                                <h1 className="text-[13px] whitespace-nowrap text-ellipsis overflow-hidden">{i.name}</h1>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="menuparentcontainR">
              <BiSearchAlt2 className="searchicon2" onClick={searchbar} />

              <div id="icons-container" onClick={() => setOpenWishlist(true)}>
                <FaRegHeart id="shoppingIcon" />
                <div>{wishlist && wishlist?.length}</div>
              </div>

              <div id="icons-container" onClick={() => navigate('/product/cart')}>
                <AiOutlineShopping id="shoppingIcon" />
                <div>{cart && cart?.length}</div>
              </div>

              <div id="icons_container">
                    {isAuthenticated ? (
                      <Link to="/profile" >
                        <img
                          src={`${user?.avatar?.url}`}
                          className="Image_Icon"
                          alt=""
                        />
                      </Link>
                    ) : (
                      <Link to="/login">
                        <BsPersonCircle id="personIcon" color="#333" />
                      </Link>
                    )}
              </div>

              {/* wishlist popup */}
              {openWishlist ? (
                <Wishlist setOpenWishlist={setOpenWishlist} />
              ) : null}
            </div>
          </>
      </div>

      {/* mobile header */}
      {/* <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
        }
      w-full h-[60px] bg-[#fff] z-50 top-0 left-0 shadow-sm 800px:hidden`}
      >
        <div className="w-full flex items-center justify-between">
          <div>
            <BiMenuAltLeft
              size={40}
              className="ml-4"
              onClick={() => setOpen(true)}
            />
          </div>
          <div>
            <Link to="/">
              <img
                src="https://shopo.quomodothemes.website/assets/images/logo.svg"
                alt=""
                className="mt-3 cursor-pointer"
              />
            </Link>
          </div>
          <div>
            <div
              className="relative mr-[20px]"
              onClick={() => setOpenCart(true)}
            >
              <AiOutlineShoppingCart size={30} />
              <span class="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                {cart && cart?.length}
              </span>
            </div>
          </div>
          {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

          {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
        </div>

        {open && (
          <div
            className={`fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0`}
          >
            <div className="fixed w-[70%] bg-[#fff] h-screen top-0 left-0 z-10 overflow-y-scroll">
              <div className="w-full justify-between flex pr-3">
                <div>
                  <div
                    className="relative mr-[15px]"
                    onClick={() => setOpenWishlist(true) || setOpen(false)}
                  >
                    <AiOutlineHeart size={30} className="mt-5 ml-3" />
                    <span class="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                      {wishlist && wishlist?.length}
                    </span>
                  </div>
                </div>
                <AiFillCloseCircle
                  id='close-icon'
                  onClick={() => setOpen(false)}
                />
              </div>

              <div className="my-8 w-[92%] m-auto h-[40px relative]">
                <input
                  type="search"
                  placeholder="Search Product..."
                  className="h-[40px] w-full px-2 border-[#3957db] border-[2px] rounded-md"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {searchData && (
                  <div className="absolute bg-[#fff] z-10 shadow w-full left-0 p-3">
                    {searchData?.map((i) => {
                      const d = i.name;

                      const Product_name = d.replace(/\s+/g, "-");
                      return (
                        <Link to={`/product/${Product_name}`}>
                          <div className="flex items-center">
                            <img
                              src={i.image_Url[0]?.url}
                              alt=""
                              className="w-[50px] mr-2"
                            />
                            <h5>{i.name}</h5>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>

              <Navbar active={activeHeading} />
              <div className={`${styles.button} ml-4 !rounded-[4px]`}>
                <Link to="/shop-create">
                  <h1 className="text-[#fff] flex items-center">
                    Become Seller <IoIosArrowForward className="ml-1" />
                  </h1>
                </Link>
              </div>
              <br />
              <br />
              <br />

              <div className="flex w-full justify-center">
                {isAuthenticated ? (
                  <div>
                    <Link to="/profile">
                      <img
                        src={`${user.avatar?.url}`}
                        alt=""
                        className="w-[60px] h-[60px] rounded-full border-[3px] border-[#0eae88]"
                      />
                    </Link>
                  </div>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="text-[18px] pr-[10px] text-[#000000b7]"
                    >
                      Login /
                    </Link>
                    <Link
                      to="/sign-up"
                      className="text-[18px] text-[#000000b7]"
                    >
                      Sign up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div> */}
    </>
  );
};

export default Navbar;
