import React from 'react'
import './Loadpending.css'
import { FaKickstarter } from 'react-icons/fa'

const Loadpending = () => {
  return (
    <>
      <FaKickstarter id='inner-alt-star'/>
    </>
  )
}

export default Loadpending