import React from 'react'
import './CollectionCard.css'
import NumberFormat from 'react-number-format';
import Progressbar from '../../Progressbar/Progressbar';
import { Link } from 'react-router-dom';


const Collectioncard3 = ({data}) => {
  const handleClick = () =>{
    // //console.log(values)
  }
  return (
    <div className='collectioncard-container_3' onClick={handleClick}>
        {/* <div className='my-image-container' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + imageurl})`}}/> */}
        <Link to={`/products/${data._id}?isEvent=true`}>
          <img
              src={`${data.images && data.images[0]?.url}`}
              alt=""
              className="my-image-container"
          />
        </Link>
        <div className='text-field'>
          <span>{data.name}</span>

          <div className='product_original_discount_price'>
            <p>
              <NumberFormat value={data.discountPrice} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
            </p>
            <p>
              <NumberFormat value={data.originalPrice} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
            </p>
          </div>
          
          <div className='progressbar-container'>
            <span>{data.stock} items instock</span>
            <Progressbar stock={data.stock} />
          </div>
        </div>
    </div>
  )
}

export default Collectioncard3