import React, { useEffect, useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import styles from "../styles/styles";
import { getAllOrdersOfUser } from "../redux/actions/order";
import { server } from "../server";
// import { RxCross1 } from "react-icons/rx";
import { AiFillCloseCircle, AiFillStar, AiOutlineStar } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";

const UserOrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  const data = orders && orders.find((item) => item._id === id);

  const reviewHandler = async (e) => {
    await axios
      .put(
        `${server}/product/create-new-review`,
        {
          user,
          rating,
          comment,
          productId: selectedItem?._id,
          orderId: id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
        setComment("");
        setRating(null);
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const refundHandler = async () => {
    await axios
      .put(`${server}/order/order-refund/${id}`, {
        status: "Processing refund",
      })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className={`py-4 gap-1 flex flex-col min-h-screen w-11/12 mx-auto`}>
      <div className="w-full px-2 border">
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center">
            <BsFillBagFill size={30} color="crimson" />
            <h1 className="pl-2 text-[25px] font-[500]">Order Details</h1>
          </div>
        </div>

        <div className="w-full flex items-center justify-between pt-3">
          <h5 className="text-[#00000084] text-[13px]">
            Order ID: <span>#{data?._id?.slice(0, 8)}</span>
          </h5>
          <h5 className="text-[#00000084] text-[13px]">
            Placed on: <span>{data?.createdAt?.slice(0, 10)}</span>
          </h5>
        </div>
      </div>

      {/* order items */}
      <br />
      {data &&
        data?.cart?.map((item, index) => {
          return (
            <div className="w-full border px-2 flex items-center py-3">
              <img
                src={`${item.images[0]?.url}`}
                alt=""
                className="w-[50x] h-[50px]"
              />
              <div className="w-full">
                <h5 className="pl-3 text-[15px]">{item.name}</h5>
                <h5 className="pl-3 text-[13px] text-[#00000091]">
                  <NumberFormat
                    value={item.discountPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />{" "}
                  X {item.qty}
                </h5>
              </div>

              {!item.isReviewed && data?.status === "Delivered" ? (
                <div
                  className={`w-[150px] bg-[green] p-2 border border text-center text-[#ffff] rounded-md cursor-pointer hover:shadow-md`}
                  onClick={() => setOpen(true) || setSelectedItem(item)}
                >
                  Write a review
                </div>
              ) : null}
            </div>
          );
        })}

      {/* review popup */}
      {open && (
        <div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
          <div className="w-[50%] h-min bg-[#fff] shadow flex flex-col gap-[15px] rounded-md p-4">
            <div className="w-full flex justify-center items-center relative">
              <AiFillCloseCircle
                id="close-icon"
                className="absolute"
                onClick={() => setOpen(false)}
                size={30}
              />

              <h5 className="text-[25px] font-[600] font-Poppins text-center">
                Give a Review
              </h5>
            </div>

            <div className="w-full flex border p-2">
              <img
                src={`${selectedItem?.images[0]?.url}`}
                alt=""
                className="w-[80px] h-[80px]"
              />

              <div>
                <div className="pl-3 text-[18px] font-[300] text-[#333]">
                  {selectedItem?.name}
                </div>
                <h4 className="pl-3 text-[20px] font-[600] text-[#333]">
                  <NumberFormat
                    value={selectedItem?.discountPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />{" "}
                  X {selectedItem?.qty}
                </h4>
              </div>
            </div>

            {/* ratings */}
            <div className="w-full flex border p-2 flex-col gap-[5px]">
              <h5 className="text-[16px] font-[500]">
                Give a Rating <span className="text-red-500">*</span>
              </h5>

              <div className="flex w-full">
                {[1, 2, 3, 4, 5]?.map((i) =>
                  rating >= i ? (
                    <AiFillStar
                      key={i}
                      className="mr-1 cursor-pointer"
                      color="rgb(246,186,0)"
                      size={20}
                      onClick={() => setRating(i)}
                    />
                  ) : (
                    <AiOutlineStar
                      key={i}
                      className="mr-1 cursor-pointer"
                      color="rgb(246,186,0)"
                      size={20}
                      onClick={() => setRating(i)}
                    />
                  )
                )}
              </div>
            </div>

            <div className="w-full flex border p-2 flex-col gap-[5px]">
              <label className="block text-[16px] font-[500]">
                Write a comment
                <span className="ml-1 font-[400] text-[16px] text-[#00000052]">
                  (optional)
                </span>
              </label>
              <textarea
                name="comment"
                id=""
                cols="20"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="How was your product? write your expresion about it!"
                className="w-[100%] border text-[13px] p-2 outline-none"
              ></textarea>
            </div>

            <div
              className={`w-[150px] bg-[#3a24db] p-2 border border-[#3a24db] text-center text-[#ffff] rounded-md cursor-pointer hover:shadow-md`}
              onClick={rating > 1 ? reviewHandler : null}
            >
              Submit
            </div>
          </div>
        </div>
      )}

      <div className="border-t w-full text-right border bg-[#4444] px-2">
        <h5 className="pt-3 text-[18px]">
          Total Price:{" "}
          <strong>
            {" "}
            <NumberFormat
              value={data?.totalPrice}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₦"}
            />{" "}
          </strong>
        </h5>
      </div>

      <br />
      <div className="w-full 800px:flex items-center gap-2">
        <div className="w-full h-[150px] 800px:w-[60%] border px-2">
          <h4 className="pt-3 text-[16px] font-[600]">Shipping Address:</h4>
          <h4 className="pt-3 text-[14px]">
            {data?.shippingAddress.address1 +
              " " +
              data?.shippingAddress.address2}
          </h4>
          <h4 className=" text-[14px]">{data?.shippingAddress.country}</h4>
          <h4 className=" text-[14px]">{data?.shippingAddress.city}</h4>
          <h4 className=" text-[14px]">{data?.user?.phoneNumber}</h4>
        </div>

        <div className="w-full h-[150px] 800px:w-[40%] border px-2">
          <h4 className="pt-3 text-[16px] font-[600]">Payment Info:</h4>
          <h4 className="pt-3 text-[14px]">
            Status:{" "}
            {data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not Paid"}
          </h4>
          <br />
          {data?.status === "Delivered" && (
            <div
              className={`w-[150px] bg-[orange] p-2 border border text-center text-[#ffff] rounded-md cursor-pointer hover:shadow-md`}
              onClick={refundHandler}
            >
              Give a Refund
            </div>
          )}
        </div>
      </div>
      <br />

      <Link to="/">
        <div
          className={`w-[150px] bg-[#3a24db] p-2 border border-[#3a24db] text-center text-[#ffff] rounded-md cursor-pointer hover:shadow-md`}
        >
          Send Message
        </div>
      </Link>
      <br />
      <br />
    </div>
  );
};

export default UserOrderDetails;
