import React, { useState, useEffect } from "react";
import ProfileSideBar from "../components/Profile/Profile/ProfileSidebar";
import ProfileContent from "../components/Profile/Profile/ProfileContent";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer";
import Loader from "../components/Layout/Loader";
import { useSelector } from "react-redux";
import "./page.css";

const ProfilePage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { loading } = useSelector((state) => state.user);
  const [active, setActive] = useState(1);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className="profilepage_container">
            <div className="profilepage_sidebar_container">
              <ProfileSideBar active={active} setActive={setActive} />
            </div>

            <div className="profilepage_body_container">
              <ProfileContent active={active} />
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default ProfilePage;
