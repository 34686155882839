import React, { useState, useEffect } from "react";
import "./Produectdetails.css";
import { FcLikePlaceholder, FcLike } from "react-icons/fc";
import { RiShieldFlashFill } from "react-icons/ri";
import { BsCart3 } from "react-icons/bs";
import NumberFormat from "react-number-format";
import Star from "../Ratings/Star";
import Stars from "../Ratings/Stars";
import Sizes from "../Sizebox/Sizes";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import { getAllProductsShop } from "../../redux/actions/product";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { toast } from "react-toastify";
import Ratings from "../Products/Ratings";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import Progressbar from "../Progressbar/Progressbar";
import { useParams, useSearchParams } from "react-router-dom";
import { DateTimes, DateTimesCheck } from "./DateScript";
import CountDown from "../Events/CountDown";

const productsize = [
  {
    size: "M",
    status: 1, // 1 : available    0: Not available
  },
  {
    size: "L",
    status: 0, // 1 : available    0: Not available
  },
  {
    size: "XL",
    status: 1, // 1 : available    0: Not available
  },
  {
    size: "XXL",
    status: 1, // 1 : available    0: Not available
  },
  {
    size: "XXXL",
    status: 0, // 1 : available    0: Not available
  },
  {
    size: "XXXXL",
    status: 0, // 1 : available    0: Not available
  },
];

const Productdetails = ({ productdata }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [count, setCount] = useState(1);

  // const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  // //console.log(productdata.reviews?.length)
  const [searchParams] = useSearchParams();
  const eventData = searchParams.get("isEvent");

  const DateTimesCheck = (props) => {
    let formattedDate;

    let start_date = props.split("T"); // "2023-10-18T00:00:00.000+00:00"
    start_date = start_date[0];
    start_date = start_date.split("-");

    let incoming_year = Number(start_date[0]);
    let incoming_month = Number(start_date[1]);
    let incoming_day = Number(start_date[2]);

    const currentDate = new Date(); // Get the current date and time

    // Format the date as per your requirement
    const current_year = Number(currentDate.getFullYear());
    const current_month = Number(currentDate.getMonth() + 1);
    const current_day = Number(currentDate.getDate());

    if (
      current_year >= incoming_year &&
      current_month >= incoming_month &&
      current_day >= incoming_day
    ) {
      formattedDate = "true"; // Event is running
    } else {
      formattedDate = "false"; // Event is not running
    }

    return formattedDate;
  };

  useEffect(() => {
    dispatch(getAllProductsShop(productdata && productdata?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === productdata?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [productdata, wishlist]);

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (productdata.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...productdata, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const incrementCount = () => {
    if (count < productdata.stock) {
      setCount(count + 1);
    }
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  return (
    <div className="Productdetails-compartment-box">
      <div className="product-title-container ">
        <span className="item-title">{productdata.name}</span>

        <div>
          {click ? (
            <AiFillHeart
              size={30}
              className="cursor-pointer"
              onClick={() => removeFromWishlistHandler(productdata)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={30}
              className="cursor-pointer"
              onClick={() => addToWishlistHandler(productdata)}
              color={click ? "red" : "orange"}
              title="Add to wishlist"
            />
          )}
        </div>
      </div>

      {eventData && (
        <div className="price-detailed-box-flash-sales border rounded">
          <div className="flashsales-box">
            <div className="flash-sales-icon-label-container">
              <RiShieldFlashFill id="flash-sales-icon" />
              <span>Flash Sales</span>
            </div>

            {DateTimesCheck(productdata.start_Date) === "true" ? (
              <div className="startdate-time">
                <span className="text-[white] text-[14px] font-[600]">
                  Time Left:
                </span>
                <span>
                  <CountDown {...productdata} />
                </span>
              </div>
            ) : (
              <div className="startdate-time">
                <span>Start on:</span>
                <span>
                  <DateTimes props={productdata.start_Date} />
                </span>
              </div>
            )}
          </div>
          <div className="w-full flex flex-col px-2 pb-2">
            <div className="w-full flex gap-1 items-center justify-between">
              <div className="price-discount-box">
                <span className="text-[20px] font-[600]">
                  <NumberFormat
                    id="payable-price"
                    allowLeadingZeros={true}
                    value={productdata.discountPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </span>
                <span className="text-[14px] font-[400]">
                  <NumberFormat
                    id="actual-price"
                    allowLeadingZeros={true}
                    value={productdata.originalPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </span>
                <span id="discount" className="text-[13px]">
                  {
                    -Math.round(
                      100 -
                        (productdata.discountPrice * 100) /
                          productdata.originalPrice
                    )
                  }
                  %
                </span>
              </div>

              <span
                id={productdata.stock > 0 ? "instock" : "outofstock"}
                className="text-[13px] text-white p-1 px-2 rounded"
              >
                {productdata.stock > 0 ? "in-stock" : "out-of-stock"}
              </span>
            </div>

            <div className="w-full flex items-center gap-2">
              <div className="items-instock text-[13px] min-w-[100px]">
                <span>{productdata.stock}</span>
                <span>items left</span>
              </div>

              <Progressbar stock={productdata.stock} />
            </div>
          </div>
        </div>
      )}

      {!eventData && (
        <div className="items-instock-box">
          <div className="items-instock text-[13px]">
            <span>{productdata.stock}</span>
            <span>Items</span>
          </div>
          <span
            id={productdata.stock > 0 ? "instock" : "outofstock"}
            className="text-[13px]"
          >
            {productdata.stock > 0 ? "in-stock" : "out-of-stock"}
          </span>
        </div>
      )}

      <div className="rating-box">
        <Ratings rating={productdata?.ratings} />
        <span>
          (
          {productdata.reviews?.length === undefined
            ? 0
            : productdata.reviews?.length}{" "}
          verified ratings)
        </span>
      </div>

      <div className="flex items-center mt-0.5 justify-between pr-3">
        <div className="flex align-center">
          <button
            className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-3 py-1 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
            onClick={decrementCount}
          >
            -
          </button>
          <div className="bg-gray-200 text-center font-[#333]-500 text-[13px] px-3 py-1 flex items-center">
            {count}
          </div>
          <button
            className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-r px-3 py-1 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
            onClick={incrementCount}
          >
            +
          </button>
        </div>

        <span className="font-[400] text-[15px] text-[#68d284]">
          {productdata?.sold_out} sold
        </span>
      </div>

      {/* <div className="variation-container-box">
            <div className="variation-title-bar">
                <span>VARIATION AVAILABLE</span>
                <span>Size Guide</span>
            </div>

            <div className="guage-container">
                {productsize?.map((itemsize, index) => {
                    return(
                        <Sizes key={index} size={itemsize.size} available={itemsize.status}/>
                    )
                })}
            </div>
        </div> */}

      <button
        className="addtocart-butn"
        onClick={() => addToCartHandler(productdata._id)}
      >
        <BsCart3 id="addto-cart-icon" />
        ADD TO CART
      </button>

      {/* <div className="product_description">
            {productdata.description}
        </div> */}
    </div>
  );
};

export default Productdetails;
