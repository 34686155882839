import React, { useEffect, useState } from "react";
import { AiFillCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import "./shop.css";
import { IoWarning, IoCloseCircleSharp } from "react-icons/io5";
import axios from "axios";
import { server } from "../../server";

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [tabtoggle, setTabtoggle] = useState(false);
  const [hide, setHide] = useState(true);
  const [categoryfield, setCategoryfield] = useState({
    name: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // function uploadSingleImage(base64) {
  //   setLoading(true);
  //   axios
  //     .post("http://localhost:5000/uploadImage", { image: base64 })
  //     .then((res) => {
  //       setUrl(res.data);
  //       alert("Image uploaded Succesfully");
  //     })
  //     .then(() => setLoading(false))
  //     .catch(//console.log);
  // }

  // function uploadMultipleImages(images) {
  //   setLoading(true);
  //   axios
  //     .post("http://localhost:8000/uploadMultipleImages", { images })
  //     .then((res) => {
  //       setUrl(res.data);
  //       alert("Image uploaded Succesfully");
  //     })
  //     .then(() => setLoading(false))
  //     .catch(//console.log);
  // }

  // const uploadImage = async (event) => {
  //   const files = event.target.files;
  //   //console.log(files?.length);

  //   if (files?.length === 1) {
  //     const base64 = await convertBase64(files[0]);
  //     uploadSingleImage(base64);
  //     return;
  //   }

  //   const base64s = [];
  //   for (var i = 0; i < files?.length; i++) {
  //     var base = await convertBase64(files[i]);
  //     base64s.push(base);
  //   }
  //   uploadMultipleImages(base64s);
  // };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate("/dashboard");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("shopId", seller._id);
    dispatch(
      createProduct({
        name,
        description,
        category,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId: seller._id,
        images,
      })
    );
  };

  const handleCollectionformSubmit = async (e) => {
    e.preventDefault();
    try {
      var config = {
        method: "POST",
        url: `${server}/category/create`,
        data: {
          ...categoryfield,
        },
        headers: {
          // Authorization: "Bearer " + cookies.Token
        },
      };

      console.log(config);

      await axios(config).then((response) => {
        console.log(response.data);
        if (response.data.success === true) {
          toast.success("Catalog Added Successfully");
        }
      });
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };

  const handleReduceImage = (imgitem, imageIndex) => {
    const filteredImage = images.filter(
      (currentItem) =>
        // currentItem.toString() !== imgitem.toString()
        images.indexOf(currentItem) !== imageIndex
    );
    setImages(filteredImage);
  };

  const handleToggle = (tabclick) => {
    setTabtoggle(!tabtoggle);
    if (tabclick === "collection") {
      setHide(true);
    } else {
      setHide(false);
    }
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setCategoryfield({
      ...categoryfield,
      [name]: value,
    });
  };

  const handleClose = () => {
    setHide(!hide);
  };

  return (
    <div className="w-[90%] 800px:w-[90%] shadow mx-8 my-10 px-3 bg-white overflow-y-auto flex flex-col gap-4">
      <div className="w-full flex items-center bg-[#002D62] p-1">
        <div
          className={`p-2 px-6 text-[13px] cursor-pointer ${
            tabtoggle
              ? "bg-[white] text-[#002D62]"
              : "bg-[#002D62] text-[white]"
          } border`}
          onClick={() => handleToggle("product")}
        >
          Product
        </div>
        <div
          className={`p-2 px-6 text-[13px] cursor-pointer ${
            tabtoggle
              ? "bg-[#002D62] text-[white]"
              : "bg-[white] text-[#002D62]"
          } border`}
          onClick={() => handleToggle("collection")}
        >
          Categories
        </div>
      </div>

      {tabtoggle ? (
        <div className="flex flex-col gap-5">
          <h5 className="text-[20px] font-[600] text-center">
            Create Products
          </h5>

          <form
            onSubmit={handleSubmit}
            className=" flex flex-col gap-3 text-[13px]"
          >
            <div className="flex gap-2">
              <div className="w-full">
                <label className="pb-2">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your product name..."
                />
              </div>

              <div className="w-full">
                <label className="pb-2">
                  Category <span className="text-red-500">*</span>
                </label>
                <select
                  className="w-full mt-2 border h-[35px] rounded-[5px]"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="Choose a category">Choose a category</option>
                  {categoriesData &&
                    categoriesData?.map((i) => (
                      <option value={i.title} key={i.title}>
                        {i.title}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div>
              <label className="pb-2">
                Description <span className="text-red-500">*</span>
              </label>
              <textarea
                cols="30"
                required
                rows="8"
                type="text"
                name="description"
                value={description}
                className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter your product description..."
              ></textarea>
            </div>

            <div className="flex gap-2">
              <div className="w-full">
                <label className="pb-2">Tags</label>
                <input
                  type="text"
                  name="tags"
                  value={tags}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setTags(e.target.value)}
                  placeholder="Enter your product tags..."
                />
              </div>

              <div className="w-full">
                <label className="pb-2">
                  Product Stock <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="price"
                  value={stock}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setStock(e.target.value)}
                  placeholder="Enter your product stock..."
                />
              </div>
            </div>

            <div className="flex gap-2">
              <div className="w-full">
                <label className="pb-2">Original Price</label>
                <input
                  type="number"
                  name="price"
                  value={originalPrice}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setOriginalPrice(e.target.value)}
                  placeholder="Enter your product price..."
                />
              </div>

              <div className="w-full">
                <label className="pb-2">
                  Price (With Discount) <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="price"
                  value={discountPrice}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setDiscountPrice(e.target.value)}
                  placeholder="Enter your product price with discount..."
                />
              </div>
            </div>

            <div>
              <label className="pb-2">
                Upload Images <span className="text-red-500">*</span>
              </label>
              <input
                type="file"
                name=""
                id="upload"
                className="hidden"
                multiple
                onChange={handleImageChange}
              />
              <div className="w-full flex items-center flex-wrap">
                <label htmlFor="upload">
                  <AiOutlinePlusCircle
                    size={30}
                    className="mt-3"
                    color="#555"
                  />
                </label>

                {images &&
                  images?.map((i, index) => (
                    <div className="picture_frame_container" key={index}>
                      <img
                        src={i}
                        key={i}
                        alt=""
                        className="h-[50px] w-[50px] object-cover m-2"
                      />
                      <AiFillCloseCircle
                        id="close-icon"
                        onClick={() => handleReduceImage(i, index)}
                      />
                    </div>
                  ))}
              </div>
              <br />
              <div>
                <input
                  type="submit"
                  value="CREATE"
                  className="mt-2 cursor-pointer appearance-none text-center block w-full p-2 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-[white] bg-[blue]"
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="flex flex-col gap-3 items-center">
          <h5 className="text-[20px] font-[600] text-center">
            Create Categories
          </h5>
          {hide && (
            <div className="flex gap-2 text-[10px] p-2 bg-[orange] items-center w-fit rounded-[3px]">
              <IoWarning size={16} color="yellow" />
              <p className="text-[#fff]">
                Note: you can only create maximum of 11 collections
              </p>

              <IoCloseCircleSharp
                size={16}
                color="yellow"
                onClick={handleClose}
                className="cursor-pointer"
              />
            </div>
          )}
          <form
            onSubmit={handleCollectionformSubmit}
            className="flex flex-col gap-3 text-[13px] w-full"
          >
            <div className="flex gap-2 w-full">
              <div className="w-full">
                <label className="pb-2">
                  Category <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={categoryfield.name}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => handleCategoryChange(e)}
                  placeholder="Enter Category Label"
                />
              </div>
            </div>

            <div className="flex gap-2 w-full">
              <div className="w-full">
                <label className="pb-2">
                  Description <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="description"
                  value={categoryfield.description}
                  className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => handleCategoryChange(e)}
                  placeholder="Enter Category Description"
                />
              </div>
            </div>

            <div className="flex gap-2 w-full">
              <div className="w-full">
                <input
                  type="submit"
                  name="name"
                  value="SUBMIT"
                  className="mt-2 cursor-pointer hover:bg-[#002D62]  hover:text-[white] appearance-none block w-full p-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CreateProduct;
