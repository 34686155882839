import CountDown from "../Events/CountDown"

let past_year = 0
let past_month = 0
let past_day = 0
let index = 0

// 10 Aug, 10:00am 
export const DateTimesCheck = (i, props) =>{
    
    let start_date = props.split('T')               // "2023-10-18T00:00:00.000+00:00"
    start_date = start_date[0]
    start_date = start_date.split('-')

    let incoming_year = Number(start_date[0])
    let incoming_month = Number(start_date[1])
    let incoming_day = Number(start_date[2])


    if(incoming_year > past_year || incoming_month > past_month || incoming_day > past_day){
        past_year = incoming_year
        past_month = incoming_month
        past_day = incoming_day
        index = i
    } 
    return index
}


const SmallestTime = ({data}) =>{
    let lengdata = data?.length

    // let finalIndex
    let mydata = data?.map((dataobjects, index) => {
        let resultingIndex = DateTimesCheck(dataobjects._id, dataobjects.Finish_Date)
        if(index === lengdata-1){
            let dataBox = data && data.find((i) => i._id === (resultingIndex !== undefined && resultingIndex));
            return CountDown(dataBox)
        }
    })

    return mydata
}

export default SmallestTime