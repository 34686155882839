import React from 'react'
import Navbar from './Navbar/Navbar'
import Ads from './Ads/Ads'
import {useSelector } from "react-redux";
import "./Header.css"


const Header = () => {
  const {isAuthenticated} = useSelector((state) => state.user);
  // const currentTab = useSelector((state) => state.currentTab.currentPage);

  return (
    <div className='header_container'>
        {/* {(isAuthenticated || currentTab === 0) && <Ads/>} */}
        <Ads/>
        <Navbar/>
    </div>
  )
}

export default Header