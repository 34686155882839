import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productData } from "../../static/data";
import styles from "../../styles/styles";
import Collectioncard from "../Cards/CollectionCard/CollectionCard1";
import "../../components/DeliveryinformationCard/DeliveryinformationCard.css";

const SuggestedProduct = ({ data }) => {
  const { allProducts } = useSelector((state) => state.products);
  const [productData, setProductData] = useState();

  useEffect(() => {
    const d =
      allProducts && allProducts.filter((i) => i.category === data.category);
    setProductData(d);
  }, []);

  return (
    <div className="w-[full] bg-[#fff] rounded-lg">
      {data ? (
        <div className={`p-3 ${styles.section} w-full`}>
          <div
            className={`w-[full] text-[16px] text-bold font-[600] border-b pb-3 mb-5 seller_information`}
          >
            Related Product from this Seller
          </div>
          <div className="grid grid-cols-1 gap-[5px] md:grid-cols-2 md:gap-[5px] lg:grid-cols-4 lg:gap-[5px] xl:grid-cols-5 xl:gap-[5px] mb-0">
            {productData &&
              productData?.map((i, index) => (
                <Collectioncard data={i} key={index} />
              ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SuggestedProduct;
