import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../components/context/SidebarContext";

const useAsync = (asyncFunction) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const {
    category,
    searchText,
    invoice,
    status,
    zone,
    time,
    sortedField,
    source,
    limitData,
    startDate,
    endDate,
    currentPage,
    isUpdate,
    setIsUpdate,
  } = useContext(SidebarContext);

  useEffect(() => {
    let unmounted = false;
    let source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await asyncFunction({ cancelToken: source.token });
        if (!unmounted) {
          setData(res);
          setError("");
          setLoading(false);
        }
      } catch (err) {
        if (!unmounted) {
          setError(err.message);
          if (axios.isCancel(err)) {
            setError("Request canceled");
          }
          setLoading(false);
          setData([]);
        }
      }
    };

    fetchData();
    setIsUpdate(false);

    return () => {
      unmounted = true;
      source.cancel("Cancelled in cleanup");
    };
  }, [
    asyncFunction,
    category,
    searchText,
    invoice,
    status,
    zone,
    time,
    sortedField,
    source,
    limitData,
    startDate,
    endDate,
    currentPage,
    isUpdate,
    setIsUpdate,
  ]);

  return { data, error, loading };
};

export default useAsync;
