import React, { useState, useEffect } from "react";
import "./ProductPreview.css";
import Storetopbox from "../../components/Storenavbar/Storetopbox";
import ItemPreview from "./ItemPreview";
import Productdetails from "../../components/Productdetails/Produectdetails";
// import Addedtocartmodal from '../../components/Addedtocartmodal/Addedtocartmodal'
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import SuggestedProduct from "../../components/Products/SuggestedProduct";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Ratings from "../../components/Products/Ratings";
import Header from "../../components/Layout/Header/Header";
import DeliveryinformationCard from "../../components/DeliveryinformationCard/DeliveryinformationCard";
import AllProduct from "../../components/Products/Product";
import Footer from "../../components/Layout/Footer";
import { SplitDate } from "../../components/Productdetails/DateScript";

const ProductPreview = () => {
  const { products } = useSelector((state) => state.products);
  const { allProducts } = useSelector((state) => state.products);
  const { allEvents } = useSelector((state) => state.events);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const eventData = searchParams.get("isEvent");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (eventData !== null) {
      const data = allEvents && allEvents.find((i) => i._id === id);
      setData(data);
    } else {
      const data = allProducts && allProducts.find((i) => i._id === id);
      setData(data);
    }
  }, [allProducts, allEvents, id]);

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews?.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  return (
    <div className="bg-[#fff] w-full min-w-[900px]">
      <Header />

      {data ? (
        <div className="ProductPreview-container">
          <Storetopbox storedata={data} />

          <div className="product-body gap-[20px] mx-[50px]">
            <div className="top-preview-container relative">
              <div className="w-[75%] h-full flex flex-col gap-4">
                <div className="product-preview h-[100%] w-full bg-[white] rounded-xl py-4 px-2 ">
                  {data && <ItemPreview dataImages={data.images} />}
                  <Productdetails productdata={data} />
                </div>

                <ProductDetailsInfo
                  data={data}
                  products={products}
                  totalReviewsLength={totalReviewsLength}
                  averageRating={averageRating}
                />
              </div>

              <div className="sticky top-0 w-[25%] h-fit">
                <DeliveryinformationCard productdata={data} />
              </div>
            </div>

            {!eventData && <>{data && <SuggestedProduct data={data} />}</>}

            {<>{data && <AllProduct title="You may also like" />}</>}
          </div>
          <br />
        </div>
      ) : null}

      <Footer />
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="w-full h-full flex flex-col gap-4 rounded-xl">
      <div className="w-full flex flex-col border-b pt-2 bg-[white] rounded-[10px] overflow-hidden pb-2">
        <div className="w-full flex justify-between border-b pb-2 px-4">
          <h5
            className={
              "text-[#000] text-[16px] py-3 leading-5 font-[600] cursor-pointer"
            }
            onClick={() => setActive(1)}
          >
            Product Details
          </h5>
        </div>

        <div
          className="w-full p-4 rounded-lg text-[13px]"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </div>

      <div className=" grow w-full flex flex-col border-b pt-2 bg-[white] rounded-[10px] overflow-hidden pb-2">
        <div className="w-full flex justify-between border-b pb-2 px-4">
          <h5
            className={
              "text-[#000] text-[16px] py-3 leading-5 font-[600] cursor-pointer"
            }
            onClick={() => setActive(1)}
          >
            Verified Customer Review
          </h5>
        </div>

        <div className="w-full min-h-[10vh] flex flex-col items-center py-3 overflow-y-auto">
          {data &&
            data.reviews?.map((item, index) => (
              <div className="w-full flex my-2 gap-3 items-center border px-4 py-2">
                <img
                  src={`${item.user.avatar?.url}`}
                  alt=""
                  className="w-[40px] h-[40px] rounded-full"
                />
                <div className=" flex flex-col gap-1 ">
                  <div className="w-full flex gap-1 items-center">
                    <h1 className="text-[13px] font-[600]">{item.user.name}</h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p className="text-[13px]">{item.comment}</p>
                  <p className="text-[13px]">
                    <SplitDate dateData={item.createdAt} />
                  </p>
                </div>
              </div>
            ))}

          <div className="w-full flex justify-center">
            {data && data.reviews === undefined ? (
              <h5>No Reviews have for this product!</h5>
            ) : data.reviews?.length === 0 ? (
              <h5>No Reviews have for this product!</h5>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPreview;
