import React from 'react'
import '../CollectionCard.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const CollectionSkeleton = ({card}) => {
  return (
    <SkeletonTheme>
      <div className={card === 1 ? 'collectioncard-container_4 px-1' : card === 2 ? 'collectioncard-container_2': 'collectioncard-container_5'}>
          <Skeleton containerClassName='image-container_2' height='100%'/>
          <Skeleton containerClassName='skeleton-text' width='100%'/>
          {card > 1 ? <>
          <Skeleton containerClassName='skeleton-text' width='100%'/>
          <Skeleton containerClassName='skeleton-text' width='100%'/></>: <></>}
      </div>
    </SkeletonTheme>
  )
}

export default CollectionSkeleton