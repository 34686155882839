import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backend_url, server } from "../../server";
import { AiOutlineCamera } from "react-icons/ai";
import styles from "../../styles/styles";
import axios from "axios";
// import { loadSeller } from "../../redux/actions/sellers";
import { toast } from "react-toastify";
import { loadSeller } from "../../redux/actions/sellers";

const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState(seller && seller.name);
  const [description, setDescription] = useState(
    seller && seller.description ? seller.description : ""
  );
  const [address, setAddress] = useState(seller && seller.address);
  const [phoneNumber, setPhoneNumber] = useState(seller && seller.phoneNumber);
  const [zipCode, setZipcode] = useState(seller && seller.zipCode);

  const dispatch = useDispatch();

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/shop/update-shop-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            dispatch(loadSeller());
            toast.success("Avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/shop/update-seller-info`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          description,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Shop info updated succesfully!");
        dispatch(loadSeller());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="w-full h-[100%] flex flex-col items-center">
      <div className="flex w-full 800px:w-[80%] flex-col gap-4 justify-center my-5">
        <div className="w-full flex items-center justify-center">
          <div className="relative">
            <img
              src={avatar ? avatar : `${seller.avatar?.url}`}
              alt=""
              className="w-[100px] h-[100px] rounded-full cursor-pointer"
            />
            <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[15px]">
              <input
                type="file"
                id="image"
                className="hidden"
                onChange={handleImage}
              />
              <label htmlFor="image">
                <AiOutlineCamera />
              </label>
            </div>
          </div>
        </div>

        {/* shop info */}
        <form
          className="flex flex-col items-center border pt-3 pb-6 w-full gap-2"
          onSubmit={updateHandler}
        >
          <div className="w-full flex flex-wrap items-center">
            <div className="w-[50%] grow min-w-[230px] flex items-center flex-col">
              <div className="w-full pl-[3%]">
                <label className="block pb-2 font-[500]">Shop Name</label>
              </div>
              <input
                type="name"
                placeholder={`${seller.name}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`${styles.input} !w-[95%] mb-2 800px:mb-0`}
                required
              />
            </div>

            <div className="w-[50%] grow min-w-[230px] flex items-center flex-col">
              <div className="w-full pl-[3%]">
                <label className="block pb-2 font-[500]">
                  Shop description
                </label>
              </div>
              <input
                type="name"
                placeholder={`${
                  seller?.description
                    ? seller.description
                    : "Enter your shop description"
                }`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`${styles.input} !w-[95%] mb-2 800px:mb-0`}
              />
            </div>
          </div>

          <div className="w-full flex flex-wrap items-center ">
            <div className="w-[50%] grow min-w-[230px] flex items-center flex-col">
              <div className="w-full pl-[3%]">
                <label className="block pb-2 font-[500]">Shop Address</label>
              </div>
              <input
                type="name"
                placeholder={seller?.address}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={`${styles.input} !w-[95%] mb-2 800px:mb-0`}
                required
              />
            </div>

            <div className="w-[50%] grow min-w-[230px] flex items-center flex-col">
              <div className="w-full pl-[3%]">
                <label className="block pb-2 font-[500]">
                  Shop Phone Number
                </label>
              </div>
              <input
                type="number"
                placeholder={seller?.phoneNumber}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className={`${styles.input} !w-[95%] mb-2 800px:mb-0`}
                required
              />
            </div>
          </div>

          <div className="w-[100%] px-3 grow min-w-[230px] flex items-center flex-col">
            <div className="w-full">
              <label className="block pb-2 font-[500]">Shop Zip Code</label>
            </div>
            <input
              type="number"
              placeholder={seller?.zipCode}
              value={zipCode}
              onChange={(e) => setZipcode(e.target.value)}
              className={`${styles.input} mb-2 800px:mb-0`}
              required
            />
          </div>

          <div className="w-[100%] flex items-center px-3 mt-5">
            <input
              type="submit"
              value="UPDATE SHOP"
              className={`w-full bg-[#3a24db] h-[40px] border border-[#3a24db] text-center text-[#ffff] rounded-[3px] mt-8 cursor-pointer`}
              required
              readOnly
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShopSettings;
