import React, { useContext, useEffect, useState } from "react";
import {
  Pagination,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  WindmillContext,
} from "@windmill/react-ui";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdOutlineAccountBalance } from "react-icons/md";
import { BsCart4, BsBoxes } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { getAllProductsShop } from "../../redux/actions/product";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import ChartCard from "../chart/ChartCard";
import LineChart from "../chart/LineChart/LineChart";
import PieChart from "../chart/Pie/PieChart";
import PageTitle from "../Typography/PageTitle";
import OrderServices from "../../services/OrderServices";

import * as dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

import useAsync from "../../hooks/useAsync";
import useFilter from "../../hooks/useFilter";

import { SidebarContext } from "../context/SidebarContext";
import TableLoading from "../preloader/TableLoading";
import OrderTable from "../order/OrderTable";
import NotFound from "../table/NotFound";

const DashboardHero = () => {
  dayjs.extend(isBetween);
  dayjs.extend(isToday);
  dayjs.extend(isYesterday);

  const [salesReport, setSalesReport] = useState([]);
  const { mode } = useContext(WindmillContext);
  const { globalSetting } = useFilter();
  const currency = globalSetting?.default_currency || "$";

  const { currentPage, handleChangePage, lang } = useContext(SidebarContext);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);

  const { data: bestSellerProductChart, loading: loadingBestSellerProduct } =
    useAsync(OrderServices.getBestSellerProductChart);

  const { data: dashboardRecentOrder, loading: loadingRecentOrder } = useAsync(
    () => OrderServices.getDashboardRecentOrder({ page: currentPage, limit: 8 })
  );

  const { data: dashboardOrderCount, loading: loadingOrderCount } = useAsync(
    OrderServices.getDashboardCount
  );

  const { data: dashboardOrderAmount, loading: loadingOrderAmount } = useAsync(
    OrderServices.getDashboardAmount
  );

  const { dataTable, serviceData } = useFilter(dashboardRecentOrder?.orders);

  useEffect(() => {
    if (seller?._id) {
      dispatch(getAllOrdersOfShop(seller._id));
      dispatch(getAllProductsShop(seller._id));
    }
  }, [dispatch, seller]);

  const availableBalance = seller?.availableBalance?.toFixed(2);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.row.status === "Delivered"
          ? "bg-[green] text-[white]"
          : params.row.status === "Processing"
          ? "bg-[red] text-[white]"
          : params.row.status === "Refund Success"
          ? "bg-[teal] text-[white]"
          : "bg-[orange] text-[white]";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/order/${params.id}`}>
            <Button>
              <AiOutlineArrowRight size={20} />
            </Button>
          </Link>
        );
      },
    },
  ];

  const rows =
    orders?.map((item) => ({
      id: item._id,
      itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
      total: "₦ " + item.totalPrice,
      status: item.status,
    })) || [];

  useEffect(() => {
    const salesOrderChartData = dashboardOrderAmount?.ordersData?.filter(
      (order) =>
        dayjs(order.updatedAt).isBetween(
          new Date().setDate(new Date().getDate() - 7),
          new Date()
        )
    );

    const report = [];
    salesOrderChartData?.reduce((res, value) => {
      let onlyDate = value.updatedAt.split("T")[0];

      if (!res[onlyDate]) {
        res[onlyDate] = { date: onlyDate, total: 0, order: 0 };
        report.push(res[onlyDate]);
      }
      res[onlyDate].total += value.total;
      res[onlyDate].order += 1;
      return res;
    }, {});

    setSalesReport(report);
  }, [dashboardOrderAmount]);

  return (
    <div className="w-full p-8">
      <h3 className="pb-2 text-[18px] font-[600]">Overview</h3>
      <div className="w-full flex-wrap gap-3 flex items-center justify-center">
        <div className="grow h-[30vh] w-[30%] min-w-[230px] bg-[#8B72BE] shadow-sm rounded-md px-3 py-5 hover:shadow-md">
          <div className=" w-full flex gap-2 items-center">
            <MdOutlineAccountBalance className="mr-2 text-[white] h-[30px] w-[30px] 800px:h-[40px] 800px:w-[50px]" />
            <h3
              className={"text-[18px] text-[white] font-[700] text-[#00000085]"}
            >
              Account Balance <br />
              <span className="text-[12px] font-[300] text-[#f5f5f5]">
                (with 10% service charge)
              </span>
            </h3>
          </div>

          <h5 className="pt-2 400px:pl-[15px] text-[#fff] text-[27px] font-[700]">
            <NumberFormat
              value={availableBalance}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₦"}
            />
          </h5>

          <Link
            to="/dashboard-withdraw-money"
            className="hover:underline underline-offset-4 decoration-white"
          >
            <h5 className="pt-4 pl-[2] text-[#fff] text-[13px]">
              Withdraw Money
            </h5>
          </Link>
        </div>

        <div className="grow h-[30vh] w-[30%] min-w-[230px] bg-[#89CFF0] shadow-sm rounded-md px-3 py-5 hover:shadow-md">
          <div className="w-full flex gap-2 items-center">
            <BsCart4 className="mr-2 text-[white] h-[30px] w-[30px] 800px:h-[40px] 800px:w-[50px]" />
            <h3
              className={"text-[18px] text-[white] font-[700] text-[#00000085]"}
            >
              Total Order <br />
              <span className="text-[12px] font-[300] text-[#f5f5f5]"></span>
            </h3>
          </div>

          <h5 className="pt-2 400px:pl-[15px] text-[#fff] text-[27px] font-[700]">
            {orders && orders?.length}
          </h5>
          <Link
            to="/dashboard-orders"
            className="hover:underline underline-offset-4 decoration-white"
          >
            <h5 className="pt-4 pl-2 text-[13px] text-[#fff]">View Orders</h5>
          </Link>
        </div>

        <div className="grow h-[30vh] w-[30%] min-w-[230px] bg-[#FFBA00] shadow-sm rounded-md px-3 py-5 hover:shadow-md">
          <div className="w-full flex gap-2 items-center">
            <BsBoxes
              size={60}
              className="mr-2 text-[white] h-[30px] w-[30px] 800px:h-[40px] 800px:w-[50px]"
            />
            <h3
              className={"text-[18px] text-[white] font-[700] text-[#00000085]"}
            >
              All Products <br />
              <span className="text-[12px] font-[300] text-[#f5f5f5]"></span>
            </h3>
          </div>

          <h5 className="pt-2 400px:pl-[15px] text-[#fff] text-[27px] font-[700]">
            {products && products?.length}
          </h5>

          <Link
            to="/dashboard-products"
            className="hover:underline underline-offset-4 decoration-white"
          >
            <h5 className="pt-4 pl-2 text-[13px] text-[#fff]">View Products</h5>
          </Link>
        </div>
      </div>

      <br />

      {/* <div className="grid gap-4 md:grid-cols-2 my-8">
        <ChartCard
          mode={mode}
          loading={loadingOrderAmount}
          title={t("WeeklySales")}
        >
          <LineChart salesReport={salesReport} />
        </ChartCard>

        <ChartCard
          mode={mode}
          loading={loadingBestSellerProduct}
          title={t("BestSellingProducts")}
        >
          <PieChart data={bestSellerProductChart} />
        </ChartCard>
      </div> */}

      <PageTitle>{t("RecentOrder")}</PageTitle>

      {loadingRecentOrder ? (
        <TableLoading row={5} col={4} />
      ) : serviceData?.length !== 0 ? (
        <TableContainer className="mb-8">
          <Table>
            <TableHeader>
              <tr>
                <TableCell>{t("InvoiceNo")}</TableCell>
                <TableCell>{t("TimeTbl")}</TableCell>
                <TableCell>{t("CustomerName")} </TableCell>
                <TableCell> {t("MethodTbl")} </TableCell>
                <TableCell> {t("AmountTbl")} </TableCell>
                <TableCell>{t("OderStatusTbl")}</TableCell>
                <TableCell>{t("ActionTbl")}</TableCell>
                <TableCell className="text-right">{t("InvoiceTbl")}</TableCell>
              </tr>
            </TableHeader>

            <OrderTable
              lang={lang}
              orders={dataTable}
              globalSetting={globalSetting}
              currency={globalSetting?.default_currency || "$"}
            />
          </Table>
          <TableFooter>
            <Pagination
              totalResults={dashboardRecentOrder?.totalOrder}
              resultsPerPage={8}
              onChange={handleChangePage}
              label="Table navigation"
            />
          </TableFooter>
        </TableContainer>
      ) : (
        <NotFound title="Sorry, There are no orders right now." />
      )}

      <br />

      <h3 className="pb-2 text-[18px] font-[600]">Recent Orders</h3>
      <div className="w-full min-h-[45vh] bg-white rounded">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    </div>
  );
};

export default DashboardHero;
