import React from 'react';
import ReactLoading from 'react-loading';
import './Loading.css'
 
const Loader = ({ type, color, text, height, width }) => (
    <div className="loadercontainer">
        <div className="loadingBox">
            <ReactLoading type={type} color={color} height={height} width={width} />
            <span id="Loadingtag">{text}</span>
        </div>
    </div>
);
 
export default Loader;