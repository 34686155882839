import React from "react";
import Slider from "react-styled-carousel";
import "./Cards.css";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import BigCard from "./CollectionCard/BigCard";
import { sliderImgData } from "../../static/data";

const Bigcard = () => {
  return (
    <div className="bigcard-container">
      <Slider
        showDots
        autoSlide={5000}
        showArrows
        padding={"0px"}
        infinite={true}
        responsive
        cardsToShow={1}
        LeftArrow={<FaChevronCircleLeft id="carousel-icons-left" />}
        RightArrow={<FaChevronCircleRight id="carousel-icons-right" />}
      >
        {sliderImgData?.map((slideImgObj, index) => (
          <BigCard key={index} {...slideImgObj} />
        ))}
      </Slider>
    </div>
  );
};

export default Bigcard;
